import _sprintfJs from "sprintf-js";
import _lookup from "gl-constants/lookup";
import _glslShaderName from "glsl-shader-name";
import _addLineNumbers from "add-line-numbers";
var exports = {};
var sprintf = _sprintfJs.sprintf;
var glConstants = _lookup;
var shaderName = _glslShaderName;
var addLineNumbers = _addLineNumbers;
exports = formatCompilerError;

function formatCompilerError(errLog, src, type) {
  "use strict";

  var name = shaderName(src) || "of unknown name (see npm glsl-shader-name)";
  var typeName = "unknown type";

  if (type !== undefined) {
    typeName = type === glConstants.FRAGMENT_SHADER ? "fragment" : "vertex";
  }

  var longForm = sprintf("Error compiling %s shader %s:\n", typeName, name);
  var shortForm = sprintf("%s%s", longForm, errLog);
  var errorStrings = errLog.split("\n");
  var errors = {};

  for (var i = 0; i < errorStrings.length; i++) {
    var errorString = errorStrings[i];
    if (errorString === "" || errorString === "\0") continue;
    var lineNo = parseInt(errorString.split(":")[2]);

    if (isNaN(lineNo)) {
      throw new Error(sprintf("Could not parse error: %s", errorString));
    }

    errors[lineNo] = errorString;
  }

  var lines = addLineNumbers(src).split("\n");

  for (var i = 0; i < lines.length; i++) {
    if (!errors[i + 3] && !errors[i + 2] && !errors[i + 1]) continue;
    var line = lines[i];
    longForm += line + "\n";

    if (errors[i + 1]) {
      var e = errors[i + 1];
      e = e.substr(e.split(":", 3).join(":").length + 1).trim();
      longForm += sprintf("^^^ %s\n\n", e);
    }
  }

  return {
    long: longForm.trim(),
    short: shortForm.trim()
  };
}

export default exports;